input[type="search"] {
	border-radius: 0px !important;
}

[data-theme="light"] {
	input[type="search"] {
		--form-element-border-color: #{$grey-100};
		box-shadow: var(--card-box-shadow);
	}
}

[data-theme="dark"] {
	input[type="search"] {
		--form-element-border-color: transparent;
		--form-element-background-color: #{$grey-900};
	}
}

