:root {
	--font-family: "Helvetica", sans-serif;
	--line-height: 1.6;
}

h2, h3, h4, h5 {
	margin-top: calc(var(--spacing) * 3);
	--typography-spacing-vertical: 1rem;
}

h2 {
	--typography-spacing-vertical: 2.5rem;
	border-bottom: 1px solid;
	border-color: var(--primary);
	color: var(--primary);
}

h3 {
	--font-size: 1.3rem;
}

p > a, [role="link"] {
	color: var(--contrast);
	text-decoration: underline;
}

blockquote {
	background: var(--card-background-color);
	box-shadow: var(--card-box-shadow);
	position: relative;

	p {
		font-size: 1.5em;
		font-style: italic;
		margin: 0;
		color: var(--muted-color);

		&:before {
			content: '"';
			color: var(--muted-color);
		}

		cite {
			font-size: 14px;
			display: block;
			margin-top: var(--spacing);
		}

	}
}

