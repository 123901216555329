/*!
 * Pico.css v1.5.7 (https://picocss.com)
 * Copyright 2019-2023 - Licensed under MIT
 */

// Config
@import "variables";

// Theming
@import "themes/default";

// Layout
@import "layout/document"; // html
@import "layout/sectioning"; // body, header, main, footer
@import "layout/container"; // .container, .container-fluid
@import "layout/section"; // section
@import "layout/grid"; // .grid
@import "layout/scroller"; // figure

// Content
@import "content/typography"; // a, headings, p, ul, blockquote, ...
@import "content/embedded"; // audio, canvas, iframe, img, svg, video
@import "content/button"; // button, a[role=button], type=button, type=submit ...
@import "content/form"; // input, select, textarea, label, fieldset, legend
@import "content/form-checkbox-radio"; // type=checkbox, type=radio, role=switch
@import "content/form-alt-input-types"; // type=color, type=date, type=file, type=search, ...
@import "content/table"; // table, tr, td, ...
@import "content/code"; // pre, code, ...
@import "content/miscs"; // hr, template, [hidden], dialog, canvas

// Components
@import "components/accordion"; // details, summary
@import "components/card"; // article
//@import "components/modal"; // dialog
@import "components/nav"; // nav
//@import "components/progress"; // progress
//@import "components/dropdown"; // dropdown

// Utilities
//@import "utilities/loading"; // aria-busy=true
//@import "utilities/tooltip"; // data-tooltip
//@import "utilities/accessibility"; // -ms-touch-action, aria-*
//@import "utilities/reduce-motion"; // prefers-reduced-motion
