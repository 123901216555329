.index-cards article {
	margin: 0;
	padding: calc(var(--spacing) * 2 );
	margin-bottom: var(--spacing);
	position: relative;
	transition: background 0.2s;

	&:hover {
		background-color: var(--card-hover-background-color);
	}

	a {
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}

		&:hover {
			text-decoration: none;
		}
	}

	h2 {
		font-size: 1.2em;
		border-bottom: 0;
		margin-bottom: calc(var(--spacing) / 2);
		margin-top: 0;
		padding-bottom: 0;
		color: var(--contrast);
	}

	p {
		margin: 0;
		color: var(--muted-color);
	}

	.date {
		color: var(--muted-color);
		margin-right: calc(var(--spacing) / 2);
		letter-spacing: 3px;
	}

	.private {
		svg {
			color: $primary-500;
			margin-right: calc(var(--spacing) / 2);
		}
	}

	.category {
		color: $primary-400;
		background: var(--contrast-focus);
		padding: 1em 1em;
		letter-spacing: 5px;
	}

	.meta {
		font-size: 10px;
		font-weight: bold;
		margin-top: var(--spacing);
		display: flex;
		align-items: center;
		text-transform: uppercase;

		@media (min-width: 992px) {
			position: absolute;
			margin: 0;
			top: 0;
			right: 0;
		}
	}
}

