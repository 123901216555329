nav {
	align-items: center;
	justify-content: center;
	background: $primary-500;
	height: calc(var(--spacing) * 3);

	ul:first-of-type {
		justify-content: flex-end;
	}

	> div {
		svg {
			width: 20px;
			height: auto;
			margin: 0 var(--spacing);
		}
	}

	ul {
		width: 100%;
		height: 100%;
		display: none;
		@media(min-width: 998px) { display: flex;}
		margin: 0!important;
	}

	a {
		color: $primary-900;
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 5px;
		font-size: 15px;
		transition: all 0.2s;

		&:hover {
			color: $primary-100;
		}
	}
}

.mobile-nav {
	display: inline-block;
	position: absolute!important;
	height: calc(var(--spacing) * 3);
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	border: 0;

	@media(min-width: 998px) { display: none;}

	ul {
		background: $primary-600;
		padding: 0.4em 0;
		position: relative;
		z-index: 1;
	}

	li {
		list-style-type: none;
		margin: 0;

		a {
			display: block;
			padding: calc(var(--spacing) / 2) 1em;
			text-transform: uppercase;
			letter-spacing: 5px;
			color: $primary-900;
		}
	}

	summary {
		border: 0;
		box-shadow: none;
		background-color: transparent;
		margin-bottom: 0!important;
		height: calc(var(--spacing) * 3);
		width: 56px;
		
		&:hover, &:active, &:focus {
			background-color: transparent;
			border-color: transparent;
		}

		&::after {
			display: none!important;
		}

		svg {
			width: 20px;
			height: 20px;
			color: $primary-900;
		}
	}
}

.toggle-theme {
	position: absolute;
	height: calc(var(--spacing) * 3);
	top: 0;
	right: 0;
	width: auto;
	color: $primary-900;
	line-height: 0;
	margin: 0;
	background: none;
	border: none;
	box-shadow: none;
}

[data-theme="light"] {
	.toggle-theme svg:first-of-type {
		display: none;
	}
}

[data-theme="dark"] {
	.toggle-theme svg:last-of-type {
		display: none;
	}
}

