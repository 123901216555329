.toc {
	margin-top: calc(var(--spacing)* 2);
	border: none;
	display: inline;

	summary {
		width: fit-content!important;
		padding: 0.5em;

		&:after {
			display: none;
		}

		svg {
			width: 30px;
			height: 30px;
		}
	}

	// Main list
	> ul {
		background: var(--card-background-color);
		text-align: left;
		padding: 1em 2em;
	}

	ul > li {
		list-style-type: disc;
	}

	a, [role="link"] {
		color: white;
	}

	// Child items
	ul > li > ul {
		margin-bottom: 2em;
	}

	ul > li > ul > li {
		list-style-type: circle;

		a {
			font-size: 0.8em;
			color: var(--muted-color);
		}
	}
}

