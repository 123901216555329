button.randomize {
	width: fit-content!important;
	padding: 0.5em;
	display: inline;

	svg {
		height: 30px;
		width: 30px;
	}
}
