.chords-and-tabs.grid {
	grid-template-columns: 1fr;

	@media screen and (min-width: 960px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	pre {
		padding: 20px;
	}

	h6 {
		margin-bottom: 10px;
		color: var(--primary);
	}
}

