$primary-hue: 25;

@import "pico/pico";
@import "typography";
@import "nav";
@import "chords-and-tabs";
@import "licks";
@import "syntax-highlights";
@import "toc";
@import "index-card";
@import "search-box";
@import "randomize";

:root {
	--border-radius: 0;
}

[data-theme="light"] {
	--code-background-color: #{$grey-900}!important;
	--muted-color: #{$grey-600}!important;
	--card-hover-background-color: white;
}

[data-theme="dark"] {
	--muted-color: #{$grey-400};
	--card-hover-background-color: #{$grey-900};
}

.page-header {
	padding: 50px 0;
	text-align: center;

	h1 {
		margin: 0;
		font-size: 3em;
	}
	p {
		font-size: 1.3em;
		color: var(--muted-color);
	}

	.date {
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 5px;
		color: var(--primary);
	}
}

.d-none {display: none;}
.text-center {text-align: center;}

.grid.max-columns-3 {
	@media screen and (min-width: 960px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.badge {
	background: var(--contrast-inverse);
	display: inline-block;
	padding: 3px 12px;
	font-size: 0.7em;
	text-transform: uppercase;
	letter-spacing: 5px;
	font-weight: bold;
}

footer {
	text-align: center;
	font-size: 0.8em;

	a {
		color: var(--contrast);
	}
}

.homepage-header h1 {
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 12px;
	font-size: 22px;
	margin: calc(var(--spacing) * 2 ) 0;
}
